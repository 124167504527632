import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";

import "./slideStyles.css";
// import required modules
import { EffectCreative, Pagination, Autoplay } from "swiper/modules";

import SlideCard from "../../components/sliderCard";
import IMG_2994 from "../../statics/IMG_2994.jpg";
import IMG_2991 from "../../statics/IMG_2991.jpg";
import IMG_2990 from "../../statics/IMG_2990.jpg";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const slidesData = [
  {
    title: "من کی‌ام؟",
    description: [
      "سلام دوست عزیزم، من دکتر مهردادم.",
      "اینجا باشگاه مجازی Dr Mehrdad Fit هستش.",
      "با بیش از 15 هزار شاگرد از سراسر جهان.",
    ],
    image: IMG_2994,
  },
  {
    title: "قراره چی کار کنیم؟",
    description: [
      "قراره با هم به صورت حرفه‌ای، توی خونه، محل کار، مسافرت یا هر جای دیگه‌ای که هستی، تمرین کنیم.",
    ],
    image: IMG_2991,
  },
  {
    title: "هدف‌مون چیه؟",
    description: [
      "هدف ما از این تمرینات اینه که، با صرف کم‌ترین وقت و هزینه، با حداقل تجهیزات، و بدون مصرف دارو و بدون آسیب تو کوتاه‌ترین زمان ممکن، به بهترین ورژن بدن خودمون برسیم.",
    ],
    image: IMG_2990,
  },
];
export default function SliderApp() {
  return (
    <Grid2 container xs={12} sx={{ width: "100%" }}>
      <Grid2
        container
        xs={12}
        sx={{
          width: "100%",
          height: "4rem",
          backgroundColor: "#000",
          // display: { xs: "none", md: "flex" },
        }}
      />
      <Swiper
        dir="rtl"
        grabCursor={true}
        effect={"creative"}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -300],
          },
          next: {
            translate: ["-100%", 0, 0],
          },
        }}
        modules={[EffectCreative, Pagination, Autoplay]}
      >
        {slidesData.map((slide) => {
          return (
            <SwiperSlide>
              <SlideCard
                description={slide.description}
                title={slide.title}
                backgroundImage={slide.image}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Grid2>
  );
}
