import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import SliderApp from "./slider";
import Highlights from "./highlights";
import Packages from "./packages";
import LoginButton from "./loginButton";
import axios from "../../common/axiosPrivate";

export default function HomePage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  const handleCheckLoggedIn = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await axios
        .get("/accounts/token/verify/", {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoggedIn(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            setOpenDialog(true);
          }
        });
    } else {
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleCheckLoggedIn();
      // showMessage();
    };

    fetchData();

    if (state?.scroll) {
      const packagesElement = document.getElementById("packages");
      packagesElement.scrollIntoView({ behavior: "smooth" });

      // var headerOffset = 80;
      // var elementPosition = packagesElement.getBoundingClientRect().top;
      // var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      // window.scrollTo({
      //   top: offsetPosition,
      //   behavior: "smooth",
      // });
    }
  }, []);

  return (
    <Grid2 container xs={12} sx={{ width: "100%" }}>
      <LoginButton isLoggedIn={isLoggedIn} />
      <SliderApp />
      <Highlights />
      <Packages isLoggedIn={isLoggedIn} />
      {/* Welcome Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>نکات مهم قبل ثبت نام</DialogTitle>
        <DialogContent>
          <p>
            <ol>
              <li style={{ marginBottom: "1rem" }}>
                سایت رو از داخل اینستاگرام باز نکن، بلکه از داخل مرورگر اصلی
                گوشیت (کروم یا سافاری) باز کن!
              </li>
              <li>حتما vpn گوشیت خاموش باشه!</li>
            </ol>
          </p>
        </DialogContent>
        <DialogActions>
          <Grid2
            container
            xs={12}
            justifyContent={"center"}
            sx={{ px: "1rem", mb: "1rem" }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{ color: "#fff" }}
              onClick={() => setOpenDialog(false)}
            >
              باشه!
            </Button>
          </Grid2>
        </DialogActions>
      </Dialog>
    </Grid2>
  );
}
